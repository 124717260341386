import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';
const locale = localization.InternalOrdering.MenuCreatorForm;

import LocalPageHeader from 'components/LocalPageHeader';
import {Button, Modal, notification, Spin, Tabs} from 'antd';
import MenuTemplateWrapper from 'pages/SalesChannel/MegaMenuTemplateDetail/MenuTemplateWrapper';
import {calculateCartMenuprice, cartCompressHandler, handleErrorFetch} from 'utils/utils';
import MenuTemplateCart from 'components/MenuTemplateCart';
import {DownOutlined, ExclamationCircleOutlined, ShopOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import SaveConfirmationModal from './SaveConfirmationModal';
import {createNewOrderTemplate, editOrderTemplate} from 'utils/request/internalOrdering';
import {isEmpty} from 'lodash';
import MenuTemplateFinder from './MenuTemplateFinder';
import {INTERNAL_ORDERING_TYPE, USE_LATEST_MENU_COMBINATION} from 'utils/constants';

const MenuCreatorForm = ({isEdit}) => {
	const navigate = useNavigate();
	const {internalOrderType} = useParams();

	const requestType = internalOrderType;

	const [loadingData, setLoadingData] = useState(true);
	const [cart, setCart] = useState([]);
	const [megaMenuTemplateData, setMegaMenuTemplateData] = useState({});
	const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);
	const [menuCombinationData] = useState({});
	const [menuTemplateFinderModal, setMenuTemplateFinderModal] = useState(isEdit ? false : true);

	const [locationLabel, setLocationLabel] = useState(null);

	const brandTabs = megaMenuTemplateData?.groupings || megaMenuTemplateData?.items || [];

	const cartSetter = item => {
		const cartTemp = JSON.parse(JSON.stringify(cart));
		const compressedCart = cartCompressHandler(cartTemp.concat(item));
		setCart(compressedCart);
	};

	const calcualteTotalCart = () => {
		let cartPrice = 0;
		cart.forEach(item => cartPrice+= calculateCartMenuprice(item, item.menuItem.quantity));
		return cartPrice;
	};

	const handleSaveMenuCombination = async menuCombinationName => {
		try {
			setLoadingSave(true);

			const payload = {
				menuTemplateId: megaMenuTemplateData.id,
				name: menuCombinationName,
				subtotal: calcualteTotalCart(),
				items: cart.map(cartItem => {
					return {
						...cartItem.menuItem,
						mappingHash: cartItem.mappingHash,
					};
				}),
			};

			if (isEdit) payload.id = menuCombinationData.id;
			else payload.type = requestType;

			const callFn = isEdit ? editOrderTemplate : createNewOrderTemplate;

			const response = await callFn(payload);

			if (response.success) {
				if (window?.opener) {
					window?.opener?.postMessage?.(USE_LATEST_MENU_COMBINATION);
					window.close();
				} else {
					navigate('/internal-ordering/menu-combination');
					notification.success({
						message: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].message,
						description: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].description,
					});
				}
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].message,
				description: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].description,
			});
		} finally {
			setLoadingSave(false);
			setSaveConfirmationModalOpen(false);
		}
	};

	const handleSetMenuTemplate = (megaMenuTemplateDataTemp, locationLabelTemp) => {
		setLocationLabel(locationLabelTemp);
		setMegaMenuTemplateData(megaMenuTemplateDataTemp);
		if ((megaMenuTemplateDataTemp?.id !== megaMenuTemplateData?.id) && cart.length) {
			setCart([]);
		}
		setLoadingData(false);
		setMenuTemplateFinderModal(false);
	};

	// const getMenuTemplate = async () => {
	// 	try {
	// 		// let megaMenuTemplateDataTemp = null;
	// 		// const response = await getActiveMenuTemplate({type: requestType});
	// 		// if (response.success && !isEmpty(response.data)) {
	// 		// 	megaMenuTemplateDataTemp = response.data;
	// 		// 	setMegaMenuTemplateData(megaMenuTemplateDataTemp);
	// 		// } else throw {};
	// 		// if (menuCombinationId) {
	// 		// 	const menuCombinationResponse = await getOrderTemplates({search: {id: menuCombinationId}});
	// 		// 	if (menuCombinationResponse.success && menuCombinationResponse.data.count) {
	// 		// 		const menuCombinationDataTemp = menuCombinationResponse.data.rows[0];
	// 		// 		setMenuCombinationData(menuCombinationDataTemp);
	// 		// 		const uniqueMenuStructure = Object.values(megaMenuTemplateDataTemp?.groupings)
	// 		// 			.reduce((acc, obj) => {
	// 		// 				obj.MenuTemplate.body.forEach(group => {
	// 		// 					group.menus.forEach(menu => {
	// 		// 						if (!acc.some(existingMenu => existingMenu.mappingHash === menu.mappingHash)) {
	// 		// 							acc.push(menu);
	// 		// 						}
	// 		// 					});
	// 		// 				});
	// 		// 				return acc;
	// 		// 			}, []);

	// 		// 		const validCart = uniqueMenuStructure
	// 		// 			.map(sourceItem => {
	// 		// 				// Find the corresponding item based on mappingHash
	// 		// 				const matchedItem = menuCombinationDataTemp.items.find(item => item.mappingHash === sourceItem.mappingHash);

	// 		// 				if (matchedItem) {
	// 		// 					// Merge the matched item with the source item
	// 		// 					return {
	// 		// 						mappingHash: sourceItem.mappingHash,
	// 		// 						dataOrigin: sourceItem,
	// 		// 						menuItem: matchedItem,
	// 		// 					};
	// 		// 				}

	// 		// 				// If no match found, return null or an empty object based on requirements
	// 		// 				return null;
	// 		// 			})
	// 		// 			.filter(result => result !== null); // Filter out any null results

	// 		// 		setCart(validCart);
	// 		// 	} else throw {};
	// 		// }
	// 	} catch (error) {
	// 		handleErrorFetch(error);
	// 	} finally {
	// 		// setLoadingData(false);
	// 	}
	// };

	const goBack = () => {
		navigate('/internal-ordering/menu-combination');
	};

	useEffect(() => {
		if (
			!requestType ||
			!Object.values(INTERNAL_ORDERING_TYPE).map(orderType => orderType.key).includes(internalOrderType)
		) return goBack();
		// getMenuTemplate();
	}, []);

	return (
		<>
			<MenuTemplateFinder
				open={menuTemplateFinderModal}
				onClose={() => isEmpty(megaMenuTemplateData) ? goBack() : setMenuTemplateFinderModal(false)}
				onOk={handleSetMenuTemplate}
			/>
			<SaveConfirmationModal
				open={saveConfirmationModalOpen}
				onSave={handleSaveMenuCombination}
				loadingSave={loadingSave}
				onClose={() => !loadingSave && setSaveConfirmationModalOpen(false)}
				isEdit={isEdit}
				currentMenuCombinationName={menuCombinationData?.name}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={isEdit
						? `${menuCombinationData?.name} (${requestType})`
						: locale.title.replace('{{type}}', requestType)}
					subtitle={megaMenuTemplateData?.id ? `Temp. ID: ${megaMenuTemplateData?.id}` : null}
					extra={(
						<div
							onClick={() => {
								Modal.confirm({
									maskClosable: true,
									icon: <ExclamationCircleOutlined />,
									centered: true,
									title: locale.ChangeOutletConfirmation.title,
									content: locale.ChangeOutletConfirmation.content,
									cancelText: locale.ChangeOutletConfirmation.cancelText,
									okText: locale.ChangeOutletConfirmation.okText,
									onOk: () => setMenuTemplateFinderModal(true),
								});
							}}
							className='px-3 py-1 rounded-sm border border-antd-netural-5 cursor-pointer'>
							<ShopOutlined />
							<span className='ml-1'>{locationLabel}</span>
							<DownOutlined
								className='text-sm opacity-20 ml-2' />
						</div>
					)}
				/>
				<div className='flex flex-col'>
					{
						loadingData
							? (
								<Spin size='lg' />
							)
							: (
								<>
									<div>
										{
											brandTabs.length
												? (
													<Tabs
														tabBarStyle={{paddingInline: '24px'}}
													>
														{
															brandTabs.map(menuTemplate => (
																<Tabs.TabPane
																	key={menuTemplate.brandId}
																	tab={menuTemplate.Brand.label}>
																	<MenuTemplateWrapper
																		menuTemplateId={menuTemplate.menuTemplateId}
																		menuTemplateGrouping={menuTemplate.MenuTemplate.body}
																		cart={cart}
																		setCart={item => cartSetter(item)}
																		isMenuCreator={true}
																	/>
																</Tabs.TabPane>
															))
														}
													</Tabs>
												)
												: null
										}
									</div>
									<div className='flex gap-2 justify-end px-6 p-6 sticky bottom-0'>
										<Button onClick={() => {
											Modal.confirm({
												icon: <ExclamationCircleOutlined />,
												centered: true,
												title: locale.CancelConfirmation.message,
												content: locale.CancelConfirmation.description,
												cancelText: locale.CancelConfirmation.cancelText,
												okText: locale.CancelConfirmation.okText,
												onOk: () => goBack(),
											});
										}}>{locale.cancel}</Button>
										<MenuTemplateCart
											cart={cart}
											setCart={setCart}
											customFooter={() => (
												<Button
													disabled={!cart.length}
													onClick={() => setSaveConfirmationModalOpen(true)}
													type='primary'
													className='w-full'
												>{isEdit ? locale.save : locale.createMenu}</Button>
											)}
										/>
									</div>
								</>
							)
					}
				</div>
			</div>
		</>
	);
};

MenuCreatorForm.defaultProps = {
	isEdit: false,
};

MenuCreatorForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default MenuCreatorForm;