import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Card, DatePicker, Input, Modal, notification, Radio, Select, Typography} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import {cloneDeep, debounce, isEmpty, isNumber} from 'lodash';
import clsx from 'clsx';
import {ExclamationCircleOutlined, FileDoneOutlined} from '@ant-design/icons';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';

// Local compoennts
import MenuCombinationSelectorModal from './MenuCombinationSelectorModal';

// Global utils
import {handleErrorFetch, removeEmptyFields} from 'utils/utils';
import {createOrderRequest, editOrderRequest, getAvailableLocation, getMapDetail} from 'utils/request/internalOrdering';
import {getOutletData} from 'utils/request/outlet';

// Request
import {INTERNAL_ORDERING_TYPE, KOL_TYPE, OUTLET_TYPE} from 'utils/constants';

const REGEX_PHONE_NUMBER = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,8}$/;
const REGEX_VALID_LINK = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const FORM_KEY = {
	INTERNAL_ORDER_CAMPAIGN_ID: 'internalOrderCampaignId',
	CUSTOMER_NAME: 'customerName',
	PHONE_NUMBER: 'phoneNumber',
	SCHEDULE_TIME: 'scheduleTime',
	ORDER_TEMPLATE_ID: 'orderTemplateId',
	DISCOUNT_PERCENTAGE: 'discountPercentage',
	LOCATION_ID: 'locationId',
	DELIVERY_METHOD: 'deliveryMethod',
	LATITUDE: 'latitude',
	LONGITUDE: 'longitude',
	ADDRESS: 'address',
	NOTES: 'notes',
	KOL_TYPE: 'kolType',
};

import localization from 'localization';
const locale = localization.InternalOrdering.CampaignRequest;

const CampaignRequest = ({isEdit, isDetail}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	const campaignId = params?.campaignId || null;
	const orderRequestId = params?.orderRequestId || null;
	const campaignType = location?.state?.campaignType || null;
	const orderType = location?.state?.orderType || null;
	const requestData = location?.state?.requestData || {};

	const isTakeaway = orderType == 'takeaway';
	const isBulk = campaignType == INTERNAL_ORDERING_TYPE.bulk.key;
	const isKOL = campaignType == INTERNAL_ORDERING_TYPE.kol.key;

	const [mapDataLoading, setMapDataLoading] = useState(false);
	const [mapData, setMapData] = useState({});

	const [outletListLoading, setOutletListLoading] = useState(false);
	const [outletList, setOutletList] = useState([]);

	const [menuCombinationModalOpen, setMenuCombinationModalOpen] = useState(false);

	const [loading, setLoading] = useState(false);
	const [requestDetails, setRequestDetails] = useState({
		internalOrderCampaignId: campaignId,
		customerName: null,
		phoneNumber: null,
		scheduleTime: null,
		orderTemplateId: null,
		type: campaignType,
		discountPercentage: null,
		locationId: null,
		orderType: orderType,
		kolType: null,
		deliveryDetails: {
			deliveryMethod: null,
			latitude: null,
			longitude: null,
			address: null,
			notes: null,
		},
	});
	const [menuCombinationInfo, setMenuCombinationInfo] = useState({
		id: null,
		label: null,
	});

	const selectedOutlet = outletList.find(outlet => outlet.id == requestDetails.locationId) || {};
	const validPhoneNumber = REGEX_PHONE_NUMBER.test(requestDetails?.phoneNumber);

	const showNearestOutlet = isKOL;

	const invalidDiscountAmount = isBulk &&
	requestDetails.discountPercentage !== null &&
	requestDetails.discountPercentage !== '' &&
	isNumber(Number(requestDetails.discountPercentage)) && (
		Number(requestDetails.discountPercentage) < 1 ||
    Number(requestDetails.discountPercentage) > 30
	);

	const getMapData = async inputtedVal => {
		try {
			const isValidLink = REGEX_VALID_LINK.test(inputtedVal);
			if (!isValidLink) return;
			setMapDataLoading(true);
			const response = await getMapDetail(inputtedVal);
			if (!isEmpty(response.data)) {
				setMapData(response.data);
				setRequestDetails(state => ({
					...state,
					deliveryDetails: {
						...state.deliveryDetails,
						...response.data,
					},
				}));
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setMapDataLoading(false);
		}
	};

	const getMapDataDebounce = useCallback(debounce(getMapData, 1000), []);

	const handleBack = () => {
		navigate(-1);
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const callPayload = cloneDeep(requestDetails);

			if (!callPayload.discountPercentage) delete callPayload.discountPercentage;
			callPayload.scheduleTime = requestDetails.scheduleTime?.toISOString?.();

			if (isEdit) callPayload.id = orderRequestId;
			if (isTakeaway) delete callPayload.deliveryDetails;

			const callFn = isEdit ? editOrderRequest : createOrderRequest;

			removeEmptyFields(callPayload);

			const response = await callFn(callPayload);

			if (response.success) {
				notification.success({
					message: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].message,
					description: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].description,
				});
				handleBack();
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].message,
				description: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].description,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleOnChangeRequestDetail = (itemKey, itemValue) => {
		if (isTakeaway) {
			setRequestDetails(state => ({
				...state,
				[itemKey]: itemValue,
			}));
		} else {
			if ((itemKey in requestDetails.deliveryDetails)) {
				setRequestDetails(state => ({
					...state,
					deliveryDetails: {
						...state.deliveryDetails,
						[itemKey]: itemValue,
					},
				}));
			} else {
				setRequestDetails(state => ({
					...state,
					[itemKey]: itemValue,
				}));
			}
		}
	};

	const BottomAction = () => {
		return (
			<div className='flex justify-end gap-2'>
				<Button
					disabled={loading}
					onClick={() => {
						isDetail
							? handleBack()
							: Modal.confirm({
								title: locale.BackConfirmation.title,
								content: locale.BackConfirmation.content,
								cancelText: locale.BackConfirmation.cancelText,
								okText: locale.BackConfirmation.okText,
								icon: <ExclamationCircleOutlined />,
								centered: true,
								maskClosable: true,
								onOk: handleBack,
							});
					}}>{isDetail ? locale.close : locale.cancel}</Button>
				{
					!isDetail && (
						<Button
							loading={loading}
							onClick={() => {
								Modal.confirm({
									title: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].title,
									content: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].content,
									cancelText: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].cancelText,
									okText: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].okText,
									icon: <ExclamationCircleOutlined />,
									centered: true,
									maskClosable: true,
									onOk: handleSubmit,
								});
							}}
							type='primary'>{isEdit ? locale.save : locale.createRequest}
						</Button>
					)
				}
			</div>
		);
	};

	const ScheduleTimeField = () => (
		<>
			{/* Schedule Time */}
			<div className='flex flex-col gap-2'>
				<div>{locale.scheduleAt}</div>
				<DatePicker
					showTime
					disabled={isDetail}
					disabledDate={current => {
						// Disable dates that have already passed
						return current && current < moment().startOf('day');
					}}
					disabledTime={current => {
						// Disable times that have already passed for today's date
						if (current && current.isSame(new Date(), 'day')) {
							const currentHour = new Date().getHours();
							const currentMinute = new Date().getMinutes();
							return {
								disabledHours: () => [...Array(currentHour).keys()],
								disabledMinutes: () => current.isSame(new Date(), 'hour')
									? [...Array(currentMinute + 10).keys()]
									: null,
								disabledSeconds: () => [],
							};
						}
						return {};
					}}
					value={
						moment.isMoment(requestDetails[FORM_KEY.SCHEDULE_TIME])
							? moment(requestDetails[FORM_KEY.SCHEDULE_TIME], 'YYYY MMM DD - HH:mm')
							: null
					}
					onChange={val => {
						if (moment.isMoment(val)) {
							handleOnChangeRequestDetail(
								FORM_KEY.SCHEDULE_TIME,
								val,
							);
						} else {
							handleOnChangeRequestDetail(FORM_KEY.SCHEDULE_TIME, null);
						}
					}}
					format='DD MMM YYYY - HH:mm'
					placeholder={locale.scheduleAtPlaceholder}
				/>
			</div>
		</>
	);

	const OutletItemForm = () => (
		<div className='flex flex-col gap-2'>
			<div>{locale.outlet}</div>
			<div>
				<Select
					status={
						isEmpty(selectedOutlet)
							? null
							: selectedOutlet?.slot == 0 && 'warning'
					}
					loading={outletListLoading}
					className='w-full'
					showSearch={!showNearestOutlet}
					filterOption={(input, option) => {
						const filteredLocation = outletList
							.filter(item => item.label.toLowerCase().includes(input.toLowerCase()))
							.map(item => item.id);

						return filteredLocation.includes(option.value);
					}}
					disabled={isDetail || outletListLoading}
					placeholder={locale.outletPlaceholder}
					value={requestDetails[FORM_KEY.LOCATION_ID]}
					onChange={val => handleOnChangeRequestDetail(FORM_KEY.LOCATION_ID, val)}
					options={outletList.map(outlet => {
						return {
							value: Number(outlet.id),
							label: (
								<div className='flex gap-2 items-center'>
									<div>{`${outlet?.label}${(showNearestOutlet && !isTakeaway) ? outlet?.distance ? ` (${outlet?.distance})` : ' -'  : ''}`}</div>
									{
										(showNearestOutlet) && (
											<div className={clsx(
												'rounded-full flex justify-center items-center h-5 w-5',
												outlet?.slot > 0
													? 'bg-antd-green-1 text-antd-green-6 border border-antd-green-3'
													: 'bg-antd-gold-1 text-antd-gold-6 border border-antd-gold-3',
											)}>
												<div className='text-xs'>
													{outlet?.slot}
												</div>
											</div>
										)
									}
								</div>
							),
						};
					})}
				>
				</Select>
				{
					showNearestOutlet
						? !isEmpty(selectedOutlet) && selectedOutlet?.slot <= 0
							? <div className='text-antd-gold-6'>{locale.outletSlotExceeded}</div>
							: (
								<div className='flex'>
									<div className='text-antd-green-6 mr-1'>•</div>
									<div className='opacity-50'>{locale.slotAvailable}</div>
									<div className='mr-2'>,</div>
									<div className='text-antd-warning-6 mr-1'>•</div>
									<div className='opacity-50'>{locale.slotUnavailable}</div>
								</div>
							)
						: null
				}
			</div>
		</div>
	);

	const getOutletBySlotting = async () => {
		const tempParams = {
			orderTemplateId: requestDetails.orderTemplateId,
			type: campaignType,
			scheduleTime: moment(requestDetails.scheduleTime, 'YYYY MMM DD - HH:mm').format('YYYY-MM-DD'),
		};

		if (!isEmpty(mapData)) {
			tempParams.latitude = mapData.latitude;
			tempParams.longitude = mapData.longitude;
		}

		const response = await getAvailableLocation(tempParams);
		return response.data;
	};

	const getDefaultLocation = async () => {
		const response = await getOutletData({limit: 0}, OUTLET_TYPE.LOCATION);
		return response?.data?.rows?.sort((a, b) => a.label.localeCompare(b.label));
	};

	const getOutletList = async () => {
		try {
			setOutletListLoading(true);

			const callFn = showNearestOutlet ? getOutletBySlotting : getDefaultLocation;

			const outletListTemp = await callFn();
			setOutletList(outletListTemp);

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setOutletListLoading(false);
		}
	};

	const handleSelectMenuCombination = selectedMenuCombination => {
		setMenuCombinationModalOpen(false);
		handleOnChangeRequestDetail(FORM_KEY.ORDER_TEMPLATE_ID, selectedMenuCombination?.id);
		setMenuCombinationInfo({
			id: selectedMenuCombination?.id,
			label: selectedMenuCombination?.name,
		});
	};

	const handlePopulateForm = () => {
		setOutletList([{
			label: requestData?.Location?.label,
			id: requestData?.locationId,
			slot: requestData?.slot,
		}]);

		setRequestDetails({
			internalOrderCampaignId: campaignId,
			customerName: requestData?.customerName,
			phoneNumber: requestData?.phoneNumber,
			kolType: requestData?.kolType,
			scheduleTime: moment(requestData?.scheduleTime).format('YYYY MMM DD - HH:mm'),
			orderTemplateId: requestData?.orderTemplateId,
			type: campaignType,
			discountPercentage: requestData?.discountPercentage || null,
			locationId: requestData?.locationId,
			orderType: orderType,
			deliveryDetails: requestData?.deliveryDetails,
		});

		setMenuCombinationInfo({
			id: requestData?.orderTemplateId,
			label: requestData?.OrderTemplate?.name,
		});
	};

	useEffect(() => {
		if (!showNearestOutlet) return;
		if (requestDetails.scheduleTime && requestDetails.orderTemplateId) {
			if (!isTakeaway && isEmpty(mapData)) return;
			getOutletList();
		}
	}, [requestDetails.scheduleTime, requestDetails.orderTemplateId, mapData]);

	useEffect(() => {
		if (requestDetails.locationId) {
			const selectedLocationStillExist = outletList.find(outlet => outlet.id == requestDetails.locationId);
			if (!selectedLocationStillExist) handleOnChangeRequestDetail(FORM_KEY.LOCATION_ID, null);
		}
	}, [outletList]);

	useEffect(() => {
		if (!orderType) return handleBack();
		if (isEdit || isDetail) handlePopulateForm();
		if (!showNearestOutlet) getOutletList();
	}, []);

	return (
		<>
			<MenuCombinationSelectorModal
				open={menuCombinationModalOpen}
				onClose={() => setMenuCombinationModalOpen(false)}
				onSelect={handleSelectMenuCombination}
			/>
			<div className='bg-white h-full overflow-auto pb-12'>
				<LocalPageHeader
					headerTitle={
						(isEdit || isDetail)
							? `${orderType} ∙ ${orderRequestId?.toUpperCase?.()}`
							: locale.title.replace('{{type}}', campaignType)
					}
				/>
				<div className='px-6'>
					<div className='flex flex-col gap-6'>
						{/* Request Detail */}
						<Card
							title={locale.requestDetail}
						>
							<div className='flex flex-col gap-6 max-w-xs'>

								{/* Menu Combination */}
								<div className='flex flex-col gap-2'>
									{
										menuCombinationInfo.id
											? (
												<>
													<Typography.Text type='secondary'>
														{locale.menuCombination}
													</Typography.Text>
													<div className='flex flex-col gap-2'>
														<div className='flex gap-2 items-center'>
															<FileDoneOutlined />
															<div className='flex gap-1'>
																<span>{`${menuCombinationInfo.label}`}</span>
																<span className='opacity-40 uppercase'>{`[${menuCombinationInfo.id}]`}</span>
															</div>
														</div>
														{
															!isDetail && (
																<div
																	onClick={() => setMenuCombinationModalOpen(true)}
																	className='text-antd-blue-6 cursor-pointer'>{locale.change}
																</div>
															)
														}
													</div>
												</>
											)
											: (
												<>
													<div>{locale.menuCombination}</div>
													<Button
														onClick={() => setMenuCombinationModalOpen(true)}
														type='primary'>{locale.selectMenuCombination}</Button>
												</>
											)
									}
								</div>

								{/* KOL Type */}
								{
									isKOL && (
										<div className='flex flex-col gap-2'>
											<div>{locale.kolType}</div>
											<Select
												disabled={isDetail}
												value={requestDetails[FORM_KEY.KOL_TYPE]}
												onChange={e => handleOnChangeRequestDetail(FORM_KEY.KOL_TYPE, e)}
												placeholder={locale.kolTypePlaceholder}
												options={Object.keys(KOL_TYPE).map(kolType => {
													return {
														label: KOL_TYPE[kolType],
														value: kolType,
													};
												})}
											/>
										</div>
									)
								}

								{/* Discount Amount */}
								{
									isBulk && (
										<div className='flex flex-col gap-2'>
											<div>{locale.discountPercentage}</div>
											<div>
												<Input
													type='number'
													disabled={isDetail}
													value={requestDetails[FORM_KEY.DISCOUNT_PERCENTAGE]}
													placeholder={locale.discountPercentagePlaceholder}
													onChange={e =>handleOnChangeRequestDetail(FORM_KEY.DISCOUNT_PERCENTAGE, e.target.value)}
												/>
												{invalidDiscountAmount && <span className='text-antd-red-6'>{locale.discountPercentageError}</span>}
											</div>
										</div>
									)
								}

								{/* Customer Name */}
								<div className='flex flex-col gap-2'>
									<div>{locale.customerName}</div>
									<Input
										disabled={isDetail}
										value={requestDetails[FORM_KEY.CUSTOMER_NAME]}
										onChange={e => handleOnChangeRequestDetail(FORM_KEY.CUSTOMER_NAME, e.target.value)}
										placeholder={locale.customerNamePlaceholder} />
								</div>

								{/* Phone Number */}
								<div className='flex flex-col gap-2'>
									<div>{locale.phoneNumber}</div>
									<div className='flex flex-col'>
										<Input
											disabled={isDetail}
											value={requestDetails[FORM_KEY.PHONE_NUMBER]}
											onChange={e => handleOnChangeRequestDetail(FORM_KEY.PHONE_NUMBER, e.target.value)}
											className='w-full'
											placeholder={locale.phoneNumberPlaceholder} />
										{
											(requestDetails[FORM_KEY.PHONE_NUMBER] && !validPhoneNumber) && (
												<span className='text-antd-red-6'>{locale.phoneNumberError}</span>
											)
										}
									</div>
								</div>

								{isTakeaway && (
									<>
										<ScheduleTimeField />
										<OutletItemForm />
									</>
								)}

							</div>

							{isTakeaway && <BottomAction />}
						</Card>

						{/* Delivery Detail */}
						{
							!isTakeaway && (
								<Card title={locale.deliveryDetail}>
									<div className='flex flex-col gap-6 max-w-xs'>

										<ScheduleTimeField />

										{/* Address Link */}
										<div className='flex flex-col gap-2'>
											<div>{locale.addressLink}</div>
											<div>
												<Input
													disabled={mapDataLoading || isDetail}
													placeholder={locale.addressLinkPlaceholder}
													onChange={e => getMapDataDebounce(e.target.value)}
												/>
												<span className='opacity-40'>{locale.addressLinkInfo}</span>
											</div>
											{
												requestDetails?.deliveryDetails?.address && (
													<Input.TextArea
														autoSize={{
															minRows: 2,
															maxRows: 5,
														}}
														value={requestDetails?.deliveryDetails?.address}
														disabled />
												)
											}
										</div>

										{/* Driver Notes */}
										<div className='flex flex-col gap-2'>
											<div>{locale.driverNotes}</div>
											<Input.TextArea
												showCount
												maxLength={150}
												placeholder={locale.driverNotesPlaceholder}
												onChange={e => handleOnChangeRequestDetail(FORM_KEY.NOTES, e.target.value)}
												value={requestDetails.deliveryDetails[FORM_KEY.NOTES]}
												disabled={isDetail}
											/>
										</div>

										{/* Vehicle */}
										<div className='flex flex-col gap-2'>
											<div>{locale.vehicle}</div>
											<div>
												<Radio.Group
													disabled={isDetail}
													value={requestDetails.deliveryDetails[FORM_KEY.DELIVERY_METHOD]}
													onChange={e => handleOnChangeRequestDetail(FORM_KEY.DELIVERY_METHOD, e.target.value)}
												>
													<Radio
														key='bike'
														value='bike'>Motorcycle</Radio>
													<Radio
														key='car'
														value='car'>Car</Radio>
												</Radio.Group>
											</div>
										</div>

										{/* Outlet */}
										<OutletItemForm />

									</div>
									<BottomAction />
								</Card>
							)
						}
					</div>
				</div>
			</div>
		</>
	);
};

CampaignRequest.defaultProps = {
	isEdit: false,
	isDetail: false,
};

CampaignRequest.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
};

export default CampaignRequest;