import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {debounce, isEmpty} from 'lodash';
import PropTypes from 'prop-types';

import {Button, Input, Modal, Radio, Select, Typography} from 'antd';

import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';
import {handleErrorFetch} from 'utils/utils';
import {getActiveMenuTemplate, getClosestLocation, getMapDetail} from 'utils/request/internalOrdering';

import localization from 'localization';
const locale = localization.InternalOrdering.MenuCreatorForm.MenuTemplateFinder;

const SelectionWrapper = ({children, onClick, isChecked, outletSelection}) => (
	<div
		onClick={onClick}
		className='rounded-sm p-4 flex border border-antd-netural-5 cursor-pointer gap-4 justify-between'
	>
		<div className='flex flex-col gap-2'>
			{children}
			{isChecked && outletSelection()}
		</div>
		<Radio
			className='m-0'
			checked={isChecked} />
	</div>
);

const LOCATION_OPTIONS = {
	BY_OUTLET: 1,
	BY_ADDRESS_LINK: 2,
};

const REGEX_VALID_LINK = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const MenuTemplateFinder = ({open, onClose, onOk}) => {
	const navigate = useNavigate();
	const {internalOrderType} = useParams();

	const requestType = internalOrderType;


	const [selectedOption, setSelectedOption] = useState(0);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const [mapData, setMapData] = useState({});
	const [nearbyOutlets, setNearbyOutlets] = useState([]);
	const [loadingNearbyOutlets, setLoadingNearbyOutlets] = useState(false);

	const [selectedLocation, setSelectedLocation] = useState(null);

	const [loading, setLoading] = useState(false);

	const handleOk = async () => {
		try {
			setLoading(true);

			const response = await getActiveMenuTemplate({
				type: requestType,
				locationId: selectedLocation,
			});

			const locationSource = selectedOption == 1 ? locationList : nearbyOutlets.map(nearbyOutlet => {
				return {
					id: nearbyOutlet.id,
					label: `${nearbyOutlet.label} (${nearbyOutlet.distance})`,
				};
			});
			const locationLabel = locationSource.find(location => location.id == selectedLocation)?.label;

			onOk(response.data, locationLabel);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchLocationList = async () => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {limit: 0, isActive: true},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				let locationListTemp = response.data.rows;
				setLocationList(locationListTemp.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
			navigate('/internal-ordering/menu-combination');
		} finally {
			setLocationLoading(false);
		}
	};

	const getMapData = async inputtedVal => {
		try {
			const isValidLink = REGEX_VALID_LINK.test(inputtedVal);
			if (!isValidLink) return;
			setLoadingNearbyOutlets(true);
			const response = await getMapDetail(inputtedVal);
			if (!isEmpty(response.data)) {

				setMapData(response.data);

				const nearbyOutletsResponse = await getClosestLocation({
					latitude: response.data.latitude,
					longitude: response.data.longitude,
					limit: 20,
				});
				if (nearbyOutletsResponse.success) {
					setNearbyOutlets(nearbyOutletsResponse.data);
				}
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingNearbyOutlets(false);
		}
	};

	const getMapDataDebounce = useCallback(debounce(getMapData, 1000), []);

	useEffect(() => {
		if (open) {
			setLoading(false);
			if (!locationList.length) fetchLocationList();
		}
	}, [open]);

	useEffect(() => {
		if (selectedOption == 1) {
			setMapData({});
			if (locationList.length) {
				setSelectedLocation(locationList[0].id);
			}
		} else {
			setSelectedLocation(null);
		}
	}, [selectedOption]);

	return (
		<Modal
			open={open}
			onCancel={onClose}
			closable={false}
			centered
			maskClosable
			footer={null}
			width={432}
		>
			<div className='flex flex-col gap-4'>
				<Typography.Title level={5}>
					{locale.title}
				</Typography.Title>
				<SelectionWrapper
					onClick={() => setSelectedOption(LOCATION_OPTIONS.BY_OUTLET)}
					isChecked={selectedOption == LOCATION_OPTIONS.BY_OUTLET}
					outletSelection={() => (
						<Select
							value={selectedLocation}
							onChange={val => setSelectedLocation(val)}
							showSearch
							loading={locationLoading}
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							options={locationList.map(location => {
								return {
									label: location.label,
									value: location.id,
								};
							})}
						/>
					)}>
					<div>
						<div className='font-medium'>{locale.byOutletTitle}</div>
						<Typography.Text type='secondary'>{locale.byOutletLabel}</Typography.Text>
					</div>
				</SelectionWrapper>
				<SelectionWrapper
					onClick={() => setSelectedOption(LOCATION_OPTIONS.BY_ADDRESS_LINK)}
					isChecked={selectedOption == LOCATION_OPTIONS.BY_ADDRESS_LINK}
					outletSelection={() => (
						<div className='flex flex-col'>
							<div>
								<Input
									loading={loadingNearbyOutlets}
									placeholder={locale.mapsLinkInputPlaceholder}
									onChange={e => getMapDataDebounce(e.target.value)}
								/>
								<Typography.Text type='secondary'>{locale.mapsLinkInputInfo}</Typography.Text>
								{
									mapData?.address && (
										<Input.TextArea
											disabled
											autoSize={{
												minRows: 2,
												maxRows: 5,
											}}
											className='mt-1'
											value={mapData?.address}
										/>
									)
								}
							</div>
							{
								mapData?.address && (
									<div className='flex flex-col gap-1'>
										<div>{locale.nearestOutlet}</div>
										<Select
											value={selectedLocation}
											onChange={val => setSelectedLocation(val)}
											showSearch
											loading={locationLoading}
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											options={nearbyOutlets.map(location => {
												return {
													label: `${location?.label} (${location?.distance})`,
													value: location.id,
												};
											})}
										/>
									</div>
								)
							}
						</div>
					)}>
					<div>
						<div className='font-medium'>{locale.byMapsLinkTitle}</div>
						<Typography.Text type='secondary'>{locale.byMapsLinkLabel}</Typography.Text>
					</div>
				</SelectionWrapper>
				<div className='flex justify-end gap-2'>
					<Button
						onClick={onClose}
						loading={loading}
					>{locale.cancelText}</Button>
					<Button
						loading={loading}
						onClick={handleOk}
						type='primary'
						disabled={!selectedLocation}>{locale.okText}</Button>
				</div>
			</div>
		</Modal>
	);
};

SelectionWrapper.defaultProps = {
	children: {},
	onClick: () => null,
	isChecked: false,
	outletSelection: () => null,
};

SelectionWrapper.propTypes = {
	children: PropTypes.object,
	onClick: PropTypes.func,
	isChecked: PropTypes.bool,
	outletSelection: PropTypes.func,
};

MenuTemplateFinder.defaultProps = {
	open: false,
	onClose: () => null,
	onOk: () => null,
};

MenuTemplateFinder.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onOk: PropTypes.func,
};

export default MenuTemplateFinder;