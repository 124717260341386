import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Sidebar.css';

import {
	ContactsOutlined, EyeOutlined, HomeOutlined,
	MoneyCollectOutlined, OrderedListOutlined, SettingOutlined,
	ShopOutlined, ShoppingCartOutlined, TagOutlined, UserOutlined,
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
const {Sider} = Layout;

import {MASTERLIST_TYPE, USER_ROLES} from 'utils/constants';
import {authChecker, getLabel} from 'utils/utils';

import FRESTOLOGOSVG from 'assets/images/fresto-logo.svg';
import FRESTOICONSVG from 'assets/images/fresto-icon.svg';

const Sidebar = ({sidebarCollapsed, openSidebar, closeSidebar}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const auth = useSelector(state => state.user);

	const handleNavigate = path => {
		navigate(path);
	};

	const routePath = location.pathname.split('/');
	if (location.pathname !== '/') {
		if (['new', 'detail', 'edit', 'history'].some(path => path === routePath.at(-1))) routePath.pop();
	}

	const sidebarLabel = ({label, linkTo, capitalize = true}) => {
		const clickHandler = e => {
			e.preventDefault();
			if (e.ctrlKey || e.button === 1) { // If user click by hold Ctrl or by scroll wheel click, open in new tab
				window.open(linkTo, '_blank');
			} else if (e.type === 'click') {
				handleNavigate(linkTo);
			}
		};
		return (
			<a
				href={linkTo}
				onClick={e => clickHandler(e)}
				className={clsx(capitalize && 'capitalize')}
			>{label}</a>
		);
	};

	const generateItems = () => {

		// Initial Sidebar
		let output = [{
			key: '/',
			label: sidebarLabel({label: 'Home', linkTo: '/'}),
			icon: <HomeOutlined />,
		}];

		// Masterlist Sidebar
		if (auth.roles.includes(USER_ROLES.SUPERADMIN) || auth.roles.some(role => Object.values(USER_ROLES.MASTERLIST).includes(role))) {
			output.push({
				key: '/masterlist',
				label: 'Masterlist',
				icon: <OrderedListOutlined />,
				children: Object.values(MASTERLIST_TYPE).map(masterlist => {
					return {
						key: `/masterlist/${masterlist}`,
						label: sidebarLabel({label: getLabel(masterlist, ' '), linkTo: `/masterlist/${masterlist}`}),
					};
				}),
			});
		}

		// Menu Template & Menu Sync Sidebar
		if (auth.roles.includes(USER_ROLES.SUPERADMIN) || auth.roles.some(role =>
			[...Object.values(USER_ROLES.MENU_TEMPLATE), ...Object.values(USER_ROLES.SYNC_MENU)]
				.includes(role))) {
			output.push({
				key: '/sales-channel',
				label: 'Sales Channel',
				icon: <ContactsOutlined />,
				children: [
					{
						key: '/sales-channel/mega-menu-template',
						label: sidebarLabel({label: 'Mega Menu Template', linkTo: '/sales-channel/mega-menu-template'}),
					},
					{
						key: '/sales-channel/menu-template',
						label: sidebarLabel({label: 'Menu Template', linkTo: '/sales-channel/menu-template'}),
					},
					{
						key: '/sales-channel/sync-request',
						label: sidebarLabel({label: 'Sync Request', linkTo: '/sales-channel/sync-request'}),
					},
					{
						key: '/sales-channel/sync-status',
						label: sidebarLabel({label: 'Sync Status', linkTo: '/sales-channel/sync-status'}),
					},
				],
			});
		}

		// Promo Sidebar
		if (
			auth.roles.includes(USER_ROLES.SUPERADMIN) ||
			auth.roles.some(role => [...Object.values(USER_ROLES.SYNC_PROMO), USER_ROLES.RESTOADMIN].includes(role))
		) {
			output.push({
				key: '/promo',
				label: 'Promo',
				icon: <TagOutlined />,
				children: [
					{
						key: '/promo/promo-request',
						label: 'Promo Request',
						className: 'capitalize',
						children: [
							{
								key: '/promo/promo-request/free-item',
								label: sidebarLabel({label: 'Free Item', linkTo: '/promo/promo-request/free-item'}),
							},
							{
								key: '/promo/promo-request/price-cut',
								label: sidebarLabel({label: 'Price Cut', linkTo: '/promo/promo-request/price-cut'}),
							},
						],
					},
					{
						key: '/promo/promo-status',
						label: 'Promo Status',
						className: 'capitalize',
						children: [
							...(
								auth.roles.some(role => [...Object.values(USER_ROLES.SYNC_PROMO), USER_ROLES.RESTOADMIN].includes(role)) ? [
									{
										key: '/promo/promo-status/free-item',
										label: sidebarLabel({label: 'Free Item', linkTo: '/promo/promo-status/free-item'}),
									},
								] : []
							),
							{
								key: '/promo/promo-status/price-cut',
								label: sidebarLabel({label: 'Price Cut', linkTo: '/promo/promo-status/price-cut'}),
							},
						],
					},
					// {
					// 	key: '/promo/promo-usage/internal',
					// 	label: sidebarLabel({label: 'Promo Usage Internal', linkTo: '/promo/promo-usage/internal'}),
					// },
				],
			});
		}

		// Outlet Sidebar
		if (auth.roles.includes(USER_ROLES.SUPERADMIN) || auth.roles.some(role => [
			USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.OUTLET.VIEWER, USER_ROLES.TRAINER.ADMIN,
			USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER, USER_ROLES.FINANCE.EDITOR,
		].includes(role))) {
			output.push({
				key: '/outlet',
				label: 'Outlet',
				icon: <ShopOutlined />,
				children: [
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.OUTLET.VIEWER], children: true}) ? [
						{
							key: '/outlet/location',
							label: sidebarLabel({label: 'Location', linkTo: '/outlet/location'}),
						},
						// {
						// 	key: '/outlet/branch',
						// 	label: 'Branch',
						// 	className: 'capitalize',
						// 	onClick: () => handleNavigate('/outlet/branch'),
						// },
						{
							key: '/outlet/brand',
							label: sidebarLabel({label: 'Brand', linkTo: '/outlet/brand'}),
						},
						{
							key: '/outlet/outlet-activation',
							label: sidebarLabel({label: 'Outlet Activation', linkTo: '/outlet/outlet-activation'}),
						},
					] : [{}]),
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR], children: true}) ? [
						{
							key: '/outlet/user-activation',
							label: sidebarLabel({label: 'User Activation', linkTo: '/outlet/user-activation'}),
						},
						{
							key: '/outlet/user-activation-fresto',
							label: sidebarLabel({label: 'User Activation FRESTO', linkTo: '/outlet/user-activation-fresto'}),
						},
					] : [{}]),
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.TRAINER.ADMIN], children: true}) ? [{
						key: '/outlet/training-session',
						label: sidebarLabel({label: 'Training Session', linkTo: '/outlet/training-session'}),
					}] : [{}]),
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.EDITOR], children: true}) ? [{
						key: '/outlet/mdr-settingss',
						label: sidebarLabel({label: 'MDR Settings', linkTo: '/outlet/mdr-settings'}),
					}] : [{}]),
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER, USER_ROLES.FINANCE.EDITOR], children: true}) ? [
						{
							key: '/outlet/company-settings',
							label: sidebarLabel({label: 'Company Settings', linkTo: '/outlet/company-settings'}),
						}] : [{}]),
				].filter(value => Object.keys(value).length !== 0),
			});
		}

		// Watchtower Sidebar
		if (auth.roles.includes(USER_ROLES.SUPERADMIN) || auth.roles.some(role => Object.values(USER_ROLES.WATCHTOWER).includes(role))) {
			output.push({
				key: '/watchtower',
				label: 'Watchtower',
				icon: <EyeOutlined />,
				children: [
					...auth.roles.some(role => [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.MENU_ADMIN].includes(role))
						? [
							{
								key: '/watchtower/menu',
								label: sidebarLabel({label: 'Menu', linkTo: '/watchtower/menu'}),
							},
						]
						: [],
					...auth.roles.some(role => [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.ORDER_ADMIN].includes(role))
						? [
							{
								key: '/watchtower/order',
								label: sidebarLabel({label: 'Order', linkTo: '/watchtower/order'}),
							},
							{
								key: '/watchtower/order/pos',
								label: sidebarLabel({label: 'Order POS', linkTo: '/watchtower/order/pos'}),
							},
						]
						: [],
					...auth.roles.some(role => [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.MENU_ADMIN].includes(role))
						? [
							{
								key: '/watchtower/outlet',
								label: 'Outlet',
								className: 'capitalize',
								onClick: () => handleNavigate('/watchtower/outlet'),
							},
						]
						: [],

					...auth.roles.some(role => [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.PROMO_ADMIN].includes(role))
						? [
							{
								key: '/watchtower/promo',
								label: sidebarLabel({label: 'Promo', linkTo: '/watchtower/promo'}),
							},
						]
						: [],
				],
			});
		}

		// Admin Sidebar
		if (auth.roles.includes(USER_ROLES.SUPERADMIN) || auth.roles.some(role => Object.values(USER_ROLES.USER).includes(role))) {
			output.push({
				key: '/admin',
				label: 'Admin',
				icon: <UserOutlined />,
				children: [
					{
						key: '/admin/user-list',
						label: sidebarLabel({label: 'User List', linkTo: '/admin/user-list'}),
					},
					{
						key: '/admin/version-settings',
						label: 'Version Settings',
						className: 'capitalize',
						children: [
							{
								key: '/admin/version-settings/version-uploader',
								label: sidebarLabel({label: 'Version Uploader', linkTo: '/admin/version-settings/version-uploader'}),
							},
							{
								key: '/admin/version-settings/location-group',
								label: sidebarLabel({label: 'Location Group', linkTo: '/admin/version-settings/location-group'}),
							},
							{
								key: '/admin/version-settings/release-scheduler',
								label: sidebarLabel({label: 'Release Scheduler', linkTo: '/admin/version-settings/release-scheduler'}),
							},
							{
								key: '/admin/version-settings/version-tracker',
								label: sidebarLabel({label: 'Version Tracker', linkTo: '/admin/version-settings/version-tracker'}),
							},
						],
					},
				],
			});
		}

		// Sales Dashboard Sidebar
		if (auth.roles.includes(USER_ROLES.SUPERADMIN) || auth.roles.some(role => Object.values(USER_ROLES.FINANCE).includes(role))) {
			output.push({
				key: '/sales-dashboard',
				label: 'Sales Dashboard',
				icon: <MoneyCollectOutlined />,
				children: [
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.FINANCE.EDITOR], children: true}) ? [
						{
							key: '/sales-dashboard/fresto-x-pos',
							label: sidebarLabel({label: 'Fresto x POS', linkTo: '/sales-dashboard/fresto-x-pos', capitalize: false}),
						},
						{
							key: '/sales-dashboard/platform-settlement',
							label: sidebarLabel({label: 'Platform Settlement', linkTo: '/sales-dashboard/platform-settlement'}),
						},
					] : []),
					{
						key: '/sales-dashboard/others',
						label: sidebarLabel({label: 'Other Sales Transaction', linkTo: '/sales-dashboard/others'}),
					},
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.FINANCE.EDITOR], children: true}) ? [
						{
							key: '/sales-dashboard/checker',
							label: sidebarLabel({label: 'Checker', linkTo: '/sales-dashboard/checker'}),
						},
						{
							key: '/sales-dashboard/file-upload',
							label: sidebarLabel({label: 'File Upload', linkTo: '/sales-dashboard/file-upload'}),
						},
					] : []),
					{
						key: '/sales-dashboard/sales-transaction',
						label: sidebarLabel({label: 'Sales Transaction', linkTo: '/sales-dashboard/sales-transaction'}),
					},
				],
			});
		}

		// POS Setting Dashboard
		if (
			auth.roles.includes(USER_ROLES.SUPERADMIN) ||
			auth.roles.some(role => [USER_ROLES.RESTOADMIN].includes(role))
		) {
			output.push({
				key: '/pos-settings',
				label: 'POS Settings',
				icon: <SettingOutlined />,
				children: [
					{
						key: '/pos-settings/platform-internal',
						label: sidebarLabel({label: 'Platform Internal', linkTo: '/pos-settings/platform-internal'}),
					},
					{
						key: '/pos-settings/payment',
						label: sidebarLabel({label: 'Payment', linkTo: '/pos-settings/payment'}),
					},
					{
						key: '/pos-settings/sales-channel',
						label: sidebarLabel({label: 'Sales Channel', linkTo: '/pos-settings/sales-channel'}),
					},
					{
						key: '/pos-settings/outlet-internal',
						label: sidebarLabel({label: 'Outlet Internal', linkTo: '/pos-settings/outlet-internal'}),
					},
				],
			});
		}

		if (authChecker({auth, requiredRolesArr: Object.values(USER_ROLES.INTERNAL_ORDERING), children: true})) {
			output.push({
				key: '/internal-ordering',
				label: 'Internal Ordering',
				icon: <ShoppingCartOutlined />,
				children: [
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_KOL], children: true})
						? [{
							key: '/internal-ordering/order-creator/kol',
							label: sidebarLabel({label: 'KOL', linkTo: '/internal-ordering/order-creator/kol'}),
						}]
						: []),
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_BULK], children: true})
						? [{
							key: '/internal-ordering/order-creator/bulk',
							label: sidebarLabel({label: 'Bulk', linkTo: '/internal-ordering/order-creator/bulk'}),
						}]
						: []),
					...(authChecker({auth, requiredRolesArr: [USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_REPLACEMENT], children: true})
						? [{
							key: '/internal-ordering/order-creator/replacement',
							label: sidebarLabel({label: 'Replacement', linkTo: '/internal-ordering/order-creator/replacement'}),
						}]
						: []),
					{
						key: '/internal-ordering/menu-combination',
						label: sidebarLabel({label: 'Menu Combination', linkTo: '/internal-ordering/menu-combination'}),
					},
				],
			});
		}

		return output;
	};

	const getSelectedItemKey = () => {
		const defaultPath = routePath?.join?.('/');
		if (location.pathname.includes('internal-ordering')) {
			const foundKey = [
				'/internal-ordering/menu-combination',
				'/internal-ordering/order-creator/kol',
				'/internal-ordering/order-creator/bulk',
				'/internal-ordering/order-creator/replacement',
			].find(sourceKey => {
				const lastSegment = defaultPath.split('/').pop();

				// If the default path contains '/internal-ordering/menu-combination', return it
				if (defaultPath.includes('/internal-ordering/menu-combination')) {
					return sourceKey === '/internal-ordering/menu-combination';
				}

				// Check if the last segment is 'bulk' or 'replacement'
				if (['bulk', 'replacement'].includes(lastSegment)) {
					return defaultPath.endsWith(sourceKey.split('/').pop()) &&
									 defaultPath.includes(sourceKey.slice(0, sourceKey.lastIndexOf('/')));
				}

				// If the last segment is not 'bulk' or 'replacement', default to 'kol'
				return sourceKey === '/internal-ordering/order-creator/kol';
			});
			return foundKey;
		} else return defaultPath;
	};

	const selectedItemKey = getSelectedItemKey();

	return (
		<Sider
			width={236}
			className='Sidebar shadow-lg h-screen overflow-auto'
			collapsed={sidebarCollapsed}
			breakpoint="lg"
			onBreakpoint={broken => {
				if (broken) openSidebar();
				else closeSidebar();
			}}
		>
			<div className='flex flex-col justify-start p-6 transition-all'>
				<a
					href='/'
					className='text-left max-w-max'>
					<img src={sidebarCollapsed ? FRESTOICONSVG : FRESTOLOGOSVG} />
				</a>
			</div>
			<Menu
				theme="dark"
				mode="inline"
				className='pb-8'
				selectedKeys={selectedItemKey}
				defaultSelectedKeys={() => `/${location.pathname.split('/').pop()}`}
				defaultOpenKeys={() => {
					if (location.pathname.includes('masterlist')) return ['/masterlist'];
					if (location.pathname.includes('sales-channel')) return ['/sales-channel'];
					if (location.pathname.includes('promo')) return ['/promo'];
					if (location.pathname.includes('outlet')) return ['/outlet'];
					if (location.pathname.includes('admin')) return ['/admin'];
					if (location.pathname.includes('watchtower')) return ['/watchtower'];
					if (location.pathname.includes('sales-dashboard')) return ['/sales-dashboard'];
					if (location.pathname.includes('pos-settings')) return ['/pos-settings'];
					if (location.pathname.includes('internal-ordering')) return ['/internal-ordering'];
				}}
				items={generateItems()}
			/>
		</Sider>
	);
};

Sidebar.defaultProps = {
	sidebarCollapsed: false,
	openSidebar: () => null,
	closeSidebar: () => null,
};

Sidebar.propTypes = {
	sidebarCollapsed: PropTypes.bool,
	openSidebar: PropTypes.func,
	closeSidebar: PropTypes.func,
};

export default Sidebar;
